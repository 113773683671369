import React, { Component, useState }  from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';
import _ from 'lodash';
import './CalendarLanguages';
import ReactModal from 'react-modal';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import { event } from 'utils/analytics';
import { GA_EVENTS, GA_ACTIONS } from 'constants/googleAnalytics';

import { Row, Col } from 'components/shared/Table';
import { Button } from 'components/shared/Forms';
import CalendarPickerHeaderComponent from './CalendarPickerHeaderComponent';
import CalendarComponent from './CalendarComponent';
import CalendarListComponent from './CalendarListComponent';
import { appInsights } from 'ApplicationInsights';
import { gotoLandingMainPage } from 'actions/activePageActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


const momentLeft = Moment();
const momentRight = Moment(momentLeft).add(1, 'M');

class CalendarPickerComponent extends Component {
    
    constructor(props) {        
    super(props);
    this.state = {
      momentLeft: momentLeft.locale(this.props.lang),
      momentRight: momentRight.locale(this.props.lang),
      questions: this.setColorAttribute(),
        activeQuestion: null,
        dispatch: null,
      showPopup: false
      };      
    this.handleMonthUpdate = this.handleMonthUpdate.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.getNextQuestion = this.getNextQuestion.bind(this);
    this.handleDaySelect = this.handleDaySelect.bind(this);
    this.setColorAttribute = this.setColorAttribute.bind(this);
        this.handelContinue = this.handelContinue.bind(this);
        this.handleokclick = this.handleokclick.bind(this);        
    this.getQuestionsAndAnsweredDates = this.getQuestionsAndAnsweredDates.bind(
      this
    );
  }   
  componentDidMount() {
    this.getNextQuestion();
  }

  setColorAttribute() {
    const questionsWithColor = this.props.questions.map((question, index) => ({
      ...question,
      color: index + 1
    }));
    return questionsWithColor;
  }

  getNextQuestion() {
    this.setState(prevState => ({
      activeQuestion: prevState.questions.find(
        question => question.answer === null
      )
    }));
  }

    getQuestionsAndAnsweredDates() {        
    const questionDates = this.state.questions.filter(x => x.answer);
    const questionAnsweredDates = questionDates.map(question => ({
      ...question,
      answer:
        typeof question.answer === 'string'
          ? question.answer
          : question.answer.format('YYYY-MM-DD HH:mm:ss')
    }));
    return questionAnsweredDates;
  }

    handleChangeDate(id) {
        let itemIdex = this.state.questions.findIndex(x => x.id === id);
        let nullItemIndex = this.state.questions.findIndex(x => x.answer === null);
        if (nullItemIndex != -1 && itemIdex != nullItemIndex) {
            let element = this.state.questions[itemIdex];
            this.state.questions.splice(itemIdex, 1);
            this.state.questions.splice(nullItemIndex, 0, element);
        }
    this.setState(
      prevState => ({
        questions: prevState.questions.map(question => {
          if (question.id === id) {
            return { ...question, answer: null };
          }
          return question;
        })
      }),
      this.getNextQuestion
    );
  }
    
    handleDaySelect(date) {                        
    const currentYear  = new Date().getFullYear();

    if (this.state.activeQuestion) {
      const activeQuestion = this.state.questions.find(
        x => x.id === this.state.activeQuestion.id
      );

        const answeredQuestion = { ...activeQuestion, answer: date };        
        //if (activeQuestion.guideWireField === "IncidentDate" && selectedDate > comparisonDate) {
        if (activeQuestion.guideWireField === "IncidentDate") {  
            const datecmpare = new Date(date);
            if (datecmpare.getFullYear() > 2024) {
                this.setState({ showPopup: true });
            }
        }
      const questions = [
        ...this.state.questions.filter(x => x.id !== answeredQuestion.id),
        answeredQuestion
      ];

      event(GA_EVENTS.QUESTIONDATES, GA_ACTIONS.ANSWER, {
        label: answeredQuestion.guideWireField
      });
      appInsights.trackEvent(
        {
          name:answeredQuestion.guideWireField
        },
        {
          EventCategory: GA_EVENTS.QUESTIONDATES,
          EventAction: GA_ACTIONS.ANSWER,
          EventLabel: answeredQuestion.guideWireField
        }
        )

      this.setState({ questions: _.values(questions) }, () => {
        this.props.handleSelectedDate(this.getQuestionsAndAnsweredDates());
        this.getNextQuestion();
      });
    }
    }


  handleMonthUpdate(type) {
    switch (type) {
      case 'sub':
        momentRight.add(-1, 'M');
        momentLeft.add(-1, 'M');
        break;
      case 'add':
        momentRight.add(1, 'M');
        momentLeft.add(1, 'M');
        break;
      default:
        break;
    }

    this.setState({
      momentLeft,
      momentRight
    });
  }

  handelContinue() {
    this.props.handelUpdateDates(this.getQuestionsAndAnsweredDates());
    }

    handleokclick() {
        this.props.actions.gotoLandingMainPage()
    }
    
    
    render() {        
    const booleanActiveQuestion = !!this.state.activeQuestion;
    const activeQuestion = this.state.activeQuestion && (
      <Slide
        direction="up"
        in={booleanActiveQuestion}
        mountOnEnter
        unmountOnExit
      >
        <Row className="p-4">
          <Col className="col-1">
            <i
              className={`material-icons align-middle small-date-icon icon-calendar-color-${this.state.activeQuestion.color}`}
            >
              fiber_manual_record
            </i>
          </Col>
          <div className="active-question col-11">
            {this.state.activeQuestion.label}{' '}
            {!this.state.activeQuestion.mandatory && (
              <Button
                type="submit"
                className="btn btn-link"
                onClick={() => this.handleDaySelect('')}
              >
                {this.props.t('commonSkip')}
              </Button>
            )}
          </div>
        </Row>
      </Slide>
    );

    const continueButton = !this.state.activeQuestion && (
      <Fade
        direction="up"
        in={!booleanActiveQuestion}
        mountOnEnter
        unmountOnExit
      >
        <Button
          id="btn-calendarPicker-continue"
          type="submit"
          className="btn btn-primary btn-lg btn-block mt-3 btn-amex-continue"
          onClick={this.handelContinue}
        >
          {this.props.t('commonContinue')}
        </Button>
      </Fade>
      );

        
        return (        
      <><Row className="no-gutters">
            <Col className="calendar-picker">
                <CalendarPickerHeaderComponent
                    leftMonthTitle={momentLeft.format('MMMM YYYY')}
                    rightMonthTitle={momentRight.format('MMMM YYYY')}
                    handleMonthUpdate={this.handleMonthUpdate} />
                <Row className="no-gutters">
                    <CalendarComponent
                        displayDate={Moment(this.state.momentLeft)}
                        left
                        displayMonth={Moment(this.state.momentLeft).format('MM')}
                        questions={this.state.questions}
                        handleDaySelect={this.handleDaySelect}
                        activeQuestion={this.state.activeQuestion}
                        languageCode={this.props.lang} />
                    <CalendarComponent
                        displayDate={Moment(this.state.momentRight)}
                        left={false}
                        displayMonth={Moment(this.state.momentRight).format('MM')}
                        questions={this.state.questions}
                        handleDaySelect={this.handleDaySelect}
                        activeQuestion={this.state.activeQuestion}
                        languageCode={this.props.lang} />
                </Row>
                <Row>
                    <Col className="col pt-3 pb-3 ml-4">
                        <h5>{this.props.title}</h5>
                    </Col>
                </Row>
                {activeQuestion}
                <Row className="no-gutters">
                    <Col>
                        <CalendarListComponent
                            questions={this.state.questions}
                            handleChangeDate={this.handleChangeDate}
                            lang={this.props.lang} />
                    </Col>
                </Row>
                <Row className="row no-gutters">
                    <Col className="m-4">{continueButton}</Col>
                </Row>
            </Col>
        </Row><ReactModal
                isOpen={this.state.showPopup}
            contentLabel="contentLabel"
            className="modal-frame"
                onRequestClose={() => this.setState({ showPopup: false })}
        >
                <div className="row">
                    <div className="col text-center py-4">
                        <span className="modal-description" dangerouslySetInnerHTML={{
                                __html: (this.props.t('customersPopup2025Msz'))
                        }}>
                        </span>
                    </div>
                </div>
                
                <div className="row border-top pt-2 mt-3">
                    <div className="col text-center">
                        <button
                            type="submit"
                            className="btn btn-primary btn-lg mt-3 btn-amex"

                                onClick={() => this.handleokclick()}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </ReactModal></>
    );
  }
}

const mapDispatchToProps = dispatch => ({
    actions: {
        ...bindActionCreators(
            {
                
                gotoLandingMainPage
                
            },
            dispatch
        )
    }
});

CalendarPickerComponent.propTypes = {
  title: PropTypes.string.isRequired,
  handelUpdateDates: PropTypes.func.isRequired,
  handleSelectedDate: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  lang: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    actions: PropTypes.shape({
        gotoLandingMainPage: PropTypes.func.isRequired
    }).isRequired
}

export default connect(null, mapDispatchToProps)(withTranslation()(CalendarPickerComponent));
